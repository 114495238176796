.p,
p {
  // @include font(18px, 32px);
  margin: 0;

  &:last-child {
    // margin-bottom: 0;
  }

  & + & {
    margin-top: 1em;

    @media screen and (max-width: $br-mobile) {
      margin-top: 0.8em;
    }
  }

  a {
    color: $c-blue;
    text-decoration: underline;
  }

  em {
    font-style: normal;
    font-weight: 400;
  }

  .cms-content & {
    margin-bottom: 1.4em;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
