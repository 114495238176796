.layout-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  flex: 1 1 auto;
  position: relative;

  // $map: (
  //   $br-1920: 60px,
  //   $br-1280: 30px,
  // );
  // @include fluid("padding-top", $map);

  // @media screen and (max-width: $br-420) {
  //   @include side("padding-top");
  // }

  &__wrap {
    @include wrap-1200;
    display: flex;
    align-items: stretch;
    flex: 1 1 auto;
    background-color: $c-grey;

    @media screen and (max-width: $br-1024) {
      display: block;
      background-color: $c-emerald;
    }
  }

  &__welcome {
    width: 565px;
    flex: 1 0 auto;

    @media screen and (max-width: $br-1024) {
      width: 100%;
    }
  }

  &__content {
    flex: 0 1 auto;
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    @include sides;

    @media screen and (max-width: $br-600) {
      margin-top: 0;
    }

    @media screen and (max-width: $br-1024) {
      padding-bottom: 45px;
    }
  }

  &__footer {
    width: 100%;
    flex: 0 1 auto;
    margin-top: auto;
    // margin-bottom: auto;

    // $map: (
    //   $br-1920: 60px,
    //   $br-1280: 15px,
    // );
    // @include fluid("padding-top", $map);

    @media screen and (max-width: $br-600) {
      padding-top: 0;
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 50vw;
    background-image: url("../../images/bg.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: -1;
  }
}
