.password {
    position: relative;

    &__show {
        @include text-link-small;
        cursor: pointer;
        position: absolute;
        bottom: 100%;
        right: 0;
        padding-bottom: 0.1em;
    }

    &__tooltip {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 0;
    }

    &__strength-meter {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
    }

    &__input {
        @include text-18;
        @include input-default;
    }

    // input {
    //     &.is-valid {
    //         background-image: url("../../images/icons/icon-correct.svg");
    //         background-position: calc(100% - 19px) center;
    //         background-size: 17px 17px;
    //         background-repeat: no-repeat;
    //     }
    // }
}