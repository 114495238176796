.footer {
  @include text-12;
  text-align: center;
  @include sides;
  border-top: 1px solid $c-shade;

  @media screen and (max-width: $br-600) {
    font-size: 11px;
  }

  &--light {
    background-color: $c-white;
    color: $c-coal;
  }

  &--dark {
    color: rgba($c-white, 0.8);
    background-color: $c-earth;

    @media screen and (max-width: $br-1024) {
      background-color: transparent;
    }
  }

  &__wrap {
    max-width: 536px;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 30px;
    padding-top: 30px;

    @media screen and (max-width: $br-600) {
      padding-bottom: 12px;
      padding-top: 12px;
    }
  }

  &__links {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      display: block;
      flex: 0 1 auto;

      &:not(:last-child) {
        &:after {
          content: "|";
          display: inline-block;
          margin-left: 1em;
          margin-right: 1em;
        }
      }
    }
  }

  &__copy {
    margin-top: 7px;
  }
}
