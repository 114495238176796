/* Keeping all font declaration here 
for consistency / avoiding dublicates
------------------------------------------------- */

.text-error {
  font-weight: 500;
  color: $c-red;
}

@mixin text-12() {
  @include font(12px, 14px, 0, 300);
  // $map: (
  //     $br-1440: 12px,
  //     // $br-1024: 11px,
  //     // $br-480: 9px,
  // );
  // @include fluid('font-size', $map);
}
.text-12 {
  @include text-12;
}
@mixin text-14() {
  @include font(14px, 19px, 0, 400);
}
.text-14 {
  @include text-14;
}

@mixin text-15() {
  @include font(15px, 18px, 0, 500);
  font-family: $f-clarimo;
}
.text-15 {
  @include text-15;
}

@mixin text-16() {
  @include font(16px, 21px, 0, 600);
}
.text-16 {
  @include text-16;
}

@mixin text-18() {
  @include font(18px, 24px, 0, 300);
}

.text-18 {
  @include text-18;
}

@mixin text-20() {
  @include font(20px, 24px, 0, 700);
  $map: (
    $br-1920: 20px,
    $br-1440: 18px,
    $br-1024: 16px,
    $br-480: 15px,
  );
  @include fluid("font-size", $map);
}

.text-20 {
  @include text-20;
}

@mixin text-22() {
  @include font(22px, 32px, 0, 300);
  $map: (
    $br-1920: 22px,
    $br-1280: 17px,
    $br-1024: 16px,
  );
  @include fluid("font-size", $map);
}

.text-22 {
  @include text-22;
}

@mixin text-24() {
  @include font(24px, 28px, 0, 700);
}

.text-24 {
  @include text-24;
}

@mixin text-25() {
  @include font(25px, 29px, 0, 700);
  $map: (
    $br-1920: 25px,
    $br-1280: 21px,
    $br-1024: 20px,
    $br-480: 16px,
  );
  @include fluid("font-size", $map);
}

.text-25 {
  @include text-25;
}

@mixin text-28() {
  @include font(28px, 32px, 0, 600);
  font-family: $f-clarimo;
}

.text-28 {
  @include text-28;
}

@mixin text-30() {
  @include font(30px, 35px, 0, 700);
  $map: (
    $br-1920: 30px,
    $br-1280: 24px,
    $br-1024: 22px,
  );
  @include fluid("font-size", $map);
}

.text-30 {
  @include text-30;
}

@mixin text-32() {
  @include font(32px, 34px, 0, 600);
  font-family: $f-clarimo;
}

.text-32 {
  @include text-32;
}

@mixin text-40() {
  @include font(40px, 47px, 0, 700);
  $map: (
    $br-1920: 40px,
    $br-1280: 32px,
    $br-1024: 30px,
    $br-480: 26px,
  );
  @include fluid("font-size", $map);
}

.text-40 {
  @include text-40;
}

@mixin text-50() {
  @include font(50px, 58px, 0, 700);
  $map: (
    $br-1920: 50px,
    $br-1440: 46px,
    $br-1024: 40px,
    $br-480: 35px,
  );
  @include fluid("font-size", $map);
}

.text-50 {
  @include text-50;
}

@mixin text-70() {
  @include font(70px, 70px, 0, 700);
  $map: (
    $br-1920: 70px,
    $br-1280: 50px,
    $br-1024: 45px,
    $br-480: 40px,
  );
  @include fluid("font-size", $map);
}

.text-70 {
  @include text-70;
}

@mixin text-link() {
  color: $c-blue;
  text-decoration: underline;
  cursor: pointer;

  &:visited {
    color: $c-purple;
  }

  &.is-disabled {
    color: $c-dirt;
    cursor: auto;
    text-decoration: none;
  }
}

.text-link {
  @include text-link;
}

@mixin text-link-small() {
  color: $c-blue;
  @include text-16;
  font-weight: 400;
  cursor: pointer;
}

.text-link-small {
  @include text-link-small;
}

@mixin text-link-main() {
  // text-transform: uppercase;
  color: $c-blue;
  cursor: pointer;
  text-decoration: underline;
}

.text-link-main {
  @include text-link-main;
}

@mixin text-input() {
  // @include font(16px, 21px, 0, 300);
  @include text-14;
}

@mixin text-input-label() {
  @include text-16;
}

@mixin text-input-error() {
  @include text-14;
  font-weight: 500;
  color: $c-red;
}

@mixin text-table-th() {
  @include text-12;
  font-weight: 700;
  text-transform: uppercase;
}
