.tab {
  $root: &;
  width: 100%;
  margin-top: 15px;

  &--pending {
    margin-top: 0;
  }

  &:first-child {
    margin-top: 0;
  }

  &__toggle {
    padding: 0 14px;
    min-height: 53px;
    border-top: 2px solid $c-emerald;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;

    @media screen and (max-width: $br-mobile) {
      display: block;
      padding: 10px 10px 0 10px;
    }

    #{$root}--equipment & {
      background-color: $c-white;
      box-shadow: 0px 6px 6px rgba($c-black, 0.16);
      border-radius: 0;
    }

    #{$root}--pending & {
      background-color: $c-white;
      border-radius: 0;
    }

    #{$root}--pending:not(:first-child) & {
      border-top: 0;
    }
  }

  &__arrow {
    flex: 0 0 auto;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    @include dropdown-arrow;

    .is-tab-open & {
      @include dropdown-arrow--open;
    }
    @media screen and (max-width: $br-mobile) {
      position: absolute;
      top: 14px;
      right: 20px;
    }
  }

  &__title {
    flex: 0 1 auto;
    @include text-14;
    font-weight: 700;
    color: $c-emerald;
    font-family: $f-clarimo;
  }

  &__content {
    flex: 0 1 auto;
  }

  &__children {
    display: none;

    .is-tab-open & {
      display: block;
    }
  }
}
