select {
  appearance: none;
  border: 0;
  margin: 0;
  padding: 0;
  text-indent: 0px;
  outline: 0;
  border-radius: 0;
}

@mixin select-default() {
  display: block;
  cursor: pointer;
  width: 100%;
  outline: none;
  border-radius: 4px;
  padding: 0.46em 0.83em;
  border: 1px solid $c-stone;

  background-image: url("../../images/icons/icon-dropdown-arrow.svg");
  background-position: calc(100% - 19px) center;
  background-size: 10px 5px;
  background-repeat: no-repeat;

  &:focus {
    // box-shadow: 0 3px 3px 0 rgba(0,0,0,.02);
  }
}

.select {
  $root: &;
  $height: 38px;
  @include text-input;
  // cursor: pointer;
  position: relative;
  text-align: left;

  @media screen and (max-width: $br-1024) {
    width: 100% !important;
  }

  &.is-open {
    z-index: $z-select-open;
  }

  &__current {
    padding: 0 45px 0 1.11em;
    height: $height;
    line-height: $height;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;

    @include dropdown-arrow;

    &:after {
      position: absolute;
      right: 20px;
    }

    .is-open & {
      @include dropdown-arrow--open;
    }
  }

  &__options {
    height: $height;
    z-index: 1;
    position: relative;
  }

  &__dropdown {
    background-color: rgba($c-emerald, 0.2);
    border: 1px solid $c-stone;
    border-radius: 4px;
    padding-top: $height - 2;
    height: 0;

    .is-open & {
      height: auto;
      background-color: $c-white;
    }

    #{$root}--white & {
      background-color: $c-white;
    }
  }

  &__option {
    height: $height;
    line-height: $height;
    padding: 0 45px 0 1.11em;
    visibility: hidden;
    pointer-events: none;
    white-space: nowrap;

    .is-open & {
      visibility: visible;
      pointer-events: auto;
    }

    &.is-selected {
      background-color: transparent !important;
      color: $c-coal !important;
    }

    &:hover {
      background-color: rgba($c-emerald, 0.2) !important;
    }
  }
}

.react-select {
  $root: &;
  cursor: pointer !important;

  &__control {
    border-color: $c-stone !important;
    cursor: pointer !important;
    // overflow: hidden;

    &--is-focused {
      border: 1px solid $c-stone !important;
      box-shadow: none !important;
    }
    &--is-disabled {
      cursor: auto !important;
      color: $c-coal !important;
      background-color: transparent !important;
    }
  }

  &__value-container {
    padding: 0.46em 0.83em !important;

    & > div {
      width: 100%;
    }
  }

  &__placeholder {
    margin: 0 !important;
    color: $c-coal !important;
  }

  &__menu-list {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  &__input {
    display: block !important;

    > input {
      width: 100% !important;
    }
  }

  &__option {
    padding: 0.46em 0.83em !important;
    cursor: pointer !important;

    &--is-selected {
      background-color: transparent !important;
      color: $c-coal !important;

      .react-select__menu-list:not(:hover) & {
        background-color: rgba($c-emerald, 0.2) !important;
      }

      @media screen and (max-width: $br-mobile) {
        background-color: rgba($c-emerald, 0.2) !important;
      }
    }

    &--is-focused {
      background-color: transparent !important;

      &:hover {
        background-color: rgba($c-emerald, 0.2) !important;
      }

      @media screen and (max-width: $br-mobile) {
        background-color: transparent !important;
      }
    }
  }

  &__single-value {
    &--is-disabled {
      color: $c-coal !important;
    }
  }
}
