.content-form {
    $root: &;

    input[type=text],
    input[type=email],
    input[type=password] {
        @include text-18;
        @include input-default;
    }

    // input[type=checkbox] {
    //     @include input-checkbox;

    //     + label {
    //         @include text-16;
    //         font-weight: 300; 
    //     }
    // }

    &__group {
        position: relative;
        display: flex;
        margin-top: 13px;

        &:first-child {
            margin-top: 0;
        }

        &--above {
            margin-top: 30px;
        }

        &--username {
            width: 50%;
        }

        &--password {
            width: 50%;
            margin-top: 30px;
        }
    }

    &__input {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        margin-right: 30px;
        
        &:last-child {
            margin-right: 0;
        }
    
        label {
            flex: 0 1 auto;
            display: block;
            white-space: nowrap;
            margin-right: 20px;
            @include text-20;
        }

        input {
            flex: 1 1 auto;
        }

        #{$root}__details-form & {
            label {
                flex: 0 0 auto;
                width: 100px;
            }
        }
    }

    &__section-login {
        margin-bottom: 55px;
    }

    &__above {
        flex: 1 1 auto;
        width: 100%;

        &--typeOfCall {
            flex: 0 1 auto;
            width: 220px;
        }

        &--replace {
            flex: 0 1 auto;
            width: 422px;
        }
    }

    &__above-label {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        position: relative;

        label {
            flex: 0 1 auto;
            display: flex;
            align-items: center;
            white-space: nowrap;
            @include text-20;
        }
    }

    &__above-action {
        flex: 0 1 auto;
        display: block;
        @include text-link-small;
        margin-left: auto;
    }

    &__above-tooltip {
        flex: 0 1 auto;
        position: relative;
        @include size(19px);
        margin-left: 20px;
    }

    &__submit {
        flex: 0 1 auto;
        width: 422px;
        margin-left: auto;
        align-self: flex-end;
    }

    &__checkbox-list {
        display: flex;
    }

    &__checkbox {
        flex: 0 1 auto;
        margin-right: 25px;
        
        &:last-child {
            margin-right: 0;
        }

        input[type=checkbox] {
            @include input-checkbox;

            + label {
                &:before {
                    top: .3em;
                }
            }
        }
    }
}