.tooltip {
  $root: &;
  cursor: pointer;
  @include size(100%);

  &__icon {
    @include size(100%);
    background-image: url("../../images/icons/icon-help.svg");
    background-size: 19px 19px;
    background-position: center;
    background-repeat: no-repeat;

    #{$root}--white & {
      background-image: url("../../images/icons/icon-help-white.svg");
    }

    @media screen and (max-width: $br-600) {
      background-size: 16px 16px;
    }
  }

  &__content {
    visibility: hidden;
    position: absolute;
    z-index: $z-tooltip;
    width: 19em;
    text-align: center;
    pointer-events: none;
    color: $c-coal;
    white-space: normal;
    @include text-16;
    text-transform: none;

    background-color: $c-white;
    padding: 2em 2.3em;
    border-radius: 4px;
    box-shadow: 0 1px 6px rgba($c-black, 0.6);
    bottom: calc(-1.5em - 25px + 50%);
    right: calc(100% + 20px);

    #{$root}--small & {
      padding: 1em 1.9em;
      width: 290px;
    }

    #{$root}--mobile & {
      pointer-events: auto;
    }

    #{$root}--top & {
      left: auto;
      bottom: calc(100% + 30px);
      right: calc(-2em - 20px + 50%);
    }

    #{$root}--top#{$root}--no-icon & {
      bottom: calc(100% + 30px);
      right: calc(-2em + 30px + 50%);
    }

    #{$root}--top#{$root}--align-left & {
      bottom: calc(100% + 30px);
      right: auto;
      left: calc(-2em - 30px - 50%);
    }

    @media screen and (max-width: $br-1024) {
      left: auto;
      bottom: calc(100% + 25px);
      right: calc(-2em - 20px + 50%);
      box-shadow: 0 6px 20px rgba($c-black, 0.8);
      width: 300px;

      #{$root}--small & {
        padding: 1em 1.9em;
        width: 220px;
      }

      #{$root}--no-icon & {
        bottom: calc(100% + 30px);
        right: calc(-2em + 30px + 50%);
      }

      #{$root}--align-left & {
        bottom: calc(100% + 30px);
        right: auto;
        left: calc(-2em - 30px - 50%);
      }
    }

    @media screen and (max-width: $br-600) {
      padding: 1.5em 1.9em;
      width: 280px;
    }

    &.is-visible {
      visibility: visible;
    }
  }

  &__arrow {
    position: absolute;
    z-index: 4;
    width: 0;
    height: 0;
    bottom: 1.5em;
    pointer-events: none;
    left: 92%;
    @include size(63px, 51px);
    transform: translateX(14px);

    &:after {
      content: "";
      position: absolute;
      @include size(100%);
      @include pos(0);
      background-size: contain;
      background-repeat: no-repeat;
      transform: rotateZ(270deg);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='63.781' height='51.336'%3E%3Cdefs%3E%3Cfilter id='a' x='0' y='0' width='63.781' height='51.336' filterUnits='userSpaceOnUse'%3E%3CfeOffset dy='6'/%3E%3CfeGaussianBlur stdDeviation='3' result='blur'/%3E%3CfeFlood flood-opacity='.38'/%3E%3CfeComposite operator='in' in2='blur'/%3E%3CfeComposite in='SourceGraphic'/%3E%3C/filter%3E%3C/defs%3E%3Cg filter='url(%23a)'%3E%3Cpath data-name='Container/Tooltip' d='M9.176 4.744c-.509-.735.129-1.653 1.151-1.655l43.115-.088c1.026 0 1.669.92 1.156 1.657l-21.65 31.127a1.479 1.479 0 01-2.307 0z' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E");
    }

    #{$root}--top & {
      left: auto;
      right: 2em;
      bottom: auto;
      top: 100%;
      transform: translateX(12px) translateY(-8px);

      &:after {
        transform: rotateZ(0deg);
      }
    }

    #{$root}--top#{$root}--align-left & {
      right: auto;
      left: calc(2em + 6px);
    }

    @media screen and (max-width: $br-1024) {
      left: auto;
      right: 2em;
      bottom: auto;
      top: 100%;
      transform: translateX(12px) translateY(-8px);

      &:after {
        transform: rotateZ(0deg);
      }

      #{$root}--align-left & {
        right: auto;
        left: calc(2em + 6px);
      }
    }
  }

  &__close {
    display: none;
    pointer-events: auto;
    @include size(24px);
    position: absolute;
    top: 6px;
    right: 6px;
    cursor: pointer;

    &:after,
    &:before {
      content: "";
      position: absolute;
      top: 10px;
      left: 3px;
      @include size(18px, 2px);
      background-color: $c-dirt;
    }

    &:after {
      transform: rotateZ(45deg);
    }

    &:before {
      transform: rotateZ(-45deg);
    }

    @media screen and (max-width: $br-600) {
      display: block;
    }
  }
}
