.form {
  $root: &;

  $spacing: (
    $br-1920: 22px,
    $br-1280: 14px,
    $br-mobile + 1: 12px,
    $br-mobile: 18px,
  );
  &__group {
    position: relative;
    display: flex;
    align-items: flex-end;

    @include fluid("margin-top", $spacing);

    &:first-child {
      margin-top: 0;
    }

    &--submit {
      justify-content: space-between;
    }

    @media screen and (max-width: $br-mobile) {
      display: block;
    }
  }

  &__col {
    width: 50%;
    flex: 0 1 auto;

    @media screen and (max-width: $br-mobile) {
      width: 100%;
      @include fluid("margin-top", $spacing);

      &:first-child {
        margin-top: 0;
      }
    }

    &:not(:last-child) {
      margin-right: 40px;

      #{$root}--labels-above & {
        margin-right: 25px;
      }
    }

    &--firstName {
      // max-width: 150px;
    }

    &--lastName {
      // flex: 1 1 auto;
    }

    &--typeOfCall {
      flex: 0 1 auto;
      margin-right: auto;
      width: 220px;

      @media screen and (max-width: $br-mobile) {
        width: 100%;
      }
    }

    &--submit {
      flex: 0 1 auto;
      margin-left: auto;
      width: auto;
    }
  }

  &__submit {
    text-align: right;
    margin-top: 32px;
  }

  &__remember {
    margin-top: 12px;
  }

  &__error {
    @include text-16;
    align-self: center;
    color: $c-red;
    @include fluid("margin-top", $spacing);
  }

  &__details {
    $map: (
      $br-1920: 40px,
      $br-1280: 26px,
      $br-480: 16px,
    );
    @include fluid("margin-top", $map);
    @include fluid("margin-bottom", $map);
  }

  &__note {
    // @include text-20;
    font-weight: 700;
    @include fluid("margin-top", $spacing);
  }
}
