.checkbox {
  $root: &;
  flex: 0 0 auto;

  &--table {
    @include size(18px);
    margin: 0 auto;
  }

  &--inline {
    display: inline-block;
    margin-right: 25px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__input {
    display: none;
  }

  &__label {
    cursor: pointer;
    display: block;
    padding-left: 26px;
    position: relative;
    @include text-input;
    font-weight: 300;

    #{$root}--inline & {
      // @include text-22;
    }

    #{$root}--table & {
      font-size: 0;
      line-height: 0;
      @include size(18px);
      padding-left: 0;
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -9px;
      display: inline-block;
      @include size(18px);
      border: 2px solid $c-dirt;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 12px 9px;
      border-radius: 3px;
    }

    #{$root}__input:checked + &:before {
      background-image: url("../../images/icons/icon-checked.svg");
    }
    #{$root}__input:disabled + & {
      cursor: auto;
    }
  }
}
