.custom-select {
    $root: &;
    $height: 38px;
    @include text-18;
    cursor: pointer;
    position: relative;

    &.is-open {
        z-index: $z-select-open;
    }

    .layout-page__control & {
        display: inline-block;
    }

    &__field {
        padding: 0 1.11em;
        height: $height;
        line-height: $height;
        position: relative;
        z-index: 2;
        @include dropdown-arrow;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:after {
            content: '';
            margin-left: 20px;
        }

        .is-open & {
            @include dropdown-arrow--open;
        }
            
    }

    &__options {
        background-color: $c-pale-orange;
        border: 1px solid $c-stone;
        border-radius: 4px;
        padding-top: $height;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;

        .is-open & {
            background-color: $c-white;
        }
        
        #{$root}--white & {
            background-color: $c-white;
        }
    }

    &__option {
        height: $height;
        line-height: $height;
        padding: 0 1.11em;
        display: none;

        .is-open & {
            display: block;
        }

        &:hover {
            background-color: $c-pale-orange !important;
        }

        &.is-selected {
            background-color: lighten($c-pale-orange, 5%);
        }
    }
}