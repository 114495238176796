@mixin textarea() {
  border: 1px solid $c-stone;
  border-radius: 4px;
  padding: 0.46em 0.83em;
  display: block;
  width: 100%;
  font-family: $f-copy;

  $map: (
    $br-1024: 135px,
    $br-480: 60px,
  );
  @include fluid("min-height", $map);
  resize: vertical;

  @include placeholder {
    color: $c-coal;
  }

  &:focus {
    border-color: transparent;
    box-shadow: 0px 1px 5px 0px $c-orange;
  }
}

textarea {
  appearance: none;
  border: 0;
  outline: none;
  background-color: transparent;
}

.textarea {
  position: relative;

  &__textarea {
    @include text-18;
    @include textarea();
  }

  &__action {
    position: absolute;
    bottom: 100%;
    right: 0;
    padding-bottom: 0.1em;

    @media screen and (max-width: $br-mobile) {
      position: static;
      padding-bottom: 0.4em;
    }
  }
}
