.pending-order {
  $root: &;

  &__action {
    flex: 0 1 auto;
    display: flex;

    & > * {
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }

      @media screen and (max-width: $br-mobile) {
        margin-right: 0;
        margin-top: 10px;

        &:first-child {
          margin-top: 0;
        }
      }
    }

    @media screen and (max-width: $br-mobile) {
      display: block;
      flex: 1 1 auto;
    }
  }

  &__group {
    $map: (
      $br-1920: 60px,
      $br-1280: 40px,
      $br-480: 20px,
    );
    @include fluid("margin-top", $map);

    &:first-child {
      margin-top: 0;
    }
  }
}
