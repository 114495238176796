.header {
  $root: &;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: $z-header;
  // @include sides;
  @include header-height("height");
  background-color: $c-white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid $c-shade;

  &__top {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include wrap-1200;
    @include sides;
  }

  &__logo {
    flex: 0 1 auto;
    width: 182px;

    // @media screen and (max-width: $br-1440) {
    //     width: 200px;
    // }

    @media screen and (max-width: $br-1024) {
      width: 112px;
    }
  }

  &__secondary {
    flex: 0 1 auto;
    display: flex;
    align-items: center;

    // .is-logged-in & {
    //   $map: (
    //     $br-1920: 30px,
    //     $br-1280: 13px,
    //     $br-1024: 8px,
    //   );
    //   @include fluid("padding-top", $map);
    //   @include fluid("padding-bottom", $map);
    // }

    @media screen and (max-width: $br-1024) {
      display: none;
    }
  }

  &__link {
    @include text-16;
    font-weight: 500;
    font-family: $f-clarimo;
    flex: 0 1 auto;
    margin-right: 2.7em;
    position: relative;

    &:last-child {
      margin-right: 0;
    }
  }

  &__menu {
    flex: 0 1 auto;
    display: flex;

    @media screen and (max-width: $br-1024) {
      display: none;
    }
  }

  &__menu-item {
    flex: 0 1 auto;
    position: relative;
    padding-right: 25px;

    @at-root a#{&} {
      cursor: pointer;
    }
  }

  &__menu-title {
    @include text-15;
    padding: 10px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    position: relative;

    cursor: pointer;

    #{$root}__menu-item:last-child & {
      margin-right: 0;
    }

    &.is-current {
      color: $c-emerald;
    }

    #{$root}__menu-item:hover & {
      color: $c-emerald;

      &:before {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $c-emerald;
      }
    }

    &--dropdown {
      @include dropdown-arrow;
      cursor: default;

      &:after {
        margin-left: 7px;
        transform: translateY(-1px);
        // position: absolute;
        // left: calc(100% + 10px);
        // top: 0;
        // height: calc(100% - 20px);
      }

      #{$root}__menu-item:hover > & {
        @include dropdown-arrow--open;
      }
    }
  }

  &__menu-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    @include text-15;
    font-weight: 500;
    width: 20.09em;
    display: none;

    #{$root}__menu-item:hover > & {
      display: block;
    }
  }

  &__account-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    // @include text-22;
    font-weight: 500;
    width: 100%;
    display: none;

    #{$root}__link:hover > & {
      display: block;
    }
  }

  &__burger {
    display: none;
    flex: 0 1 auto;
    height: 36px;
    width: 36px;

    z-index: 3;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;

    span {
      flex: 0 0 auto;
      display: block;
      @include size(20px, 2px);
      background-color: $c-black;
      margin-top: 3px;

      &:first-child {
        margin-top: 0;
      }
    }

    @media screen and (max-width: $br-1024) {
      display: flex;
    }
  }

  &__mobile-menu {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    pointer-events: none;
    display: none;

    @media screen and (max-width: $br-1024) {
      display: block;
    }
  }

  &__mobile-overlay {
    @include posD;
    position: fixed;
    z-index: 1;
    pointer-events: none;

    .is-mobile-menu-open & {
      pointer-events: auto;
    }
  }

  &__account {
    text-align: center;
    min-width: 157px;
    @include header-height("height");
    @include dropdown-arrow;
    padding: 0 15px;
    border-right: 1px solid $c-grey;
    border-left: 1px solid $c-grey;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:after {
      margin: 5px auto 0;
    }
  }

  &__account-icon {
    width: auto;
  }

  &__account-name {
    @include text-15;
    margin-top: 10px;
  }

  &__account-company {
    flex: 0 1 auto;
    @include text-12;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }
}
