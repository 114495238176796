.confirmation {
  $root: &;
  max-width: 760px;
  @include wrap;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 30px;

  &__h {
    @include text-50;
    margin-bottom: 0.6em;
  }

  &__text {
  }
}
