h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  display: block;
  color: inherit;

  // &:last-child {
  //     margin-bottom: 0;
  // }
}

h1,
.h1 {
  @include text-32;
}

h2,
.h2 {
  @include font(30px, 32px, 0, 600);
  font-family: $f-clarimo;
}

h3,
.h3 {
  @include text-28;
}

h4,
.h4 {
  @include text-18;
  font-weight: 600;
  font-family: $f-clarimo;
  color: $c-emerald;
}

h5,
.h5 {
}

h6,
.h6 {
}
