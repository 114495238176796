.copy-clipboard {
    $root: &;

    cursor: pointer;
    @include size(21px);
    background-image: url("../../images/icons/icon-document.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    @media screen and (max-width: $br-mobile) {
        display: none;
    }
}