@mixin side($prop, $multiplier: 1) {
    #{$prop}: 33px*$multiplier;

    @media screen and (max-width: $br-768) {
        #{$prop}: 16px*$multiplier;
    }
}

@mixin sides() {
    @include side('padding-left');
    @include side('padding-right');
}

.sides {
    @include sides
}