.button-group {
  $root: &;
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
