@mixin wrap() {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@mixin wrap-1640() {
  @include wrap;
  max-width: 1640px;
}

@mixin wrap-1200() {
  @include wrap;
  max-width: 1200px;
}
