.title-secure {
    display: flex;
    align-items: center;

    &:after {
        content: '';
        flex: 0 0 auto;
        display: block;
        height: 22px;
        width: 18px;
        margin-left: 12px;
        margin-bottom: -0.1em;

        background-image: url("../../images/icons/icon-verified.svg");
        background-position: center;
        background-repeat: no-repeat;
        
    }
}