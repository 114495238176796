body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  // @include font(16px, 22px, 0.31px, 400);
  background-color: $c-bg;
  color: $c-coal;
  @include text-14;
  font-family: $f-copy;

  @include header-height("padding-top");
  min-height: 100vh;
  //   height: 100vh;
  position: relative;
  display: block;
  overflow-y: auto;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;

  // &:after {
  //   content: "";
  //   display: block;
  //   position: fixed;
  //   left: 0;
  //   top: 0;
  //   width: 100%;
  //   max-width: 952px;
  //   padding-bottom: 91%;
  //   z-index: -1;
  //   background: url("../../images/bg.jpg") linear-gradient(#e66465, #9198e5);
  //   background-repeat: no-repeat;
  //   background-position: center;
  //   background-size: cover;
  // }

  // overflow: hidden;
  // position: fixed;
  // top: 0;
  // left: 0;
  // @include size(100%);
}
