.card {
  $root: &;
  text-align: center;

  &__icon {
    background-color: $c-white;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 140px;
    box-shadow: 0 3px 6px rgba($c-black, 0.08);
    border: 1px solid $c-shade;
  }

  &__title {
    @include text-14;
    font-weight: 500;
    font-family: $f-clarimo;
    margin-top: 19px;
  }
}
