.mobile-menu {
  $root: &;
  background-color: $c-white;
  height: 100vh;
  padding: 30px;
  @include header-height("padding-top");
  box-shadow: 0px 4px 6px rgba($c-black, 0.16);
  transform: translate3d(100%, 0, 0);
  transition: transform 0.8s $ease-out-Quint;
  pointer-events: none;
  overflow-y: auto;
  overflow-x: hidden;

  .is-mobile-menu-open & {
    pointer-events: auto;
    transform: translate3d(0, 0, 0);
  }

  $map: (
    $br-1024: 300px,
    $br-480: 190px,
  );
  @include fluid("width", $map);

  &__item {
    margin-top: 22px;

    // &:first-child {
    //     margin-top: 0;
    // }
    &--0 {
      @include text-20;
    }

    &--1 {
      padding-left: 10px;
      @include text-20;
    }

    &--2 {
      padding-left: 10px;
      @include text-20;
    }
  }

  &__title {
    font-weight: 400;
    color: $c-blue;
    position: relative;
    display: block;
    cursor: pointer;

    &.is-current {
      font-weight: 700;
      color: $c-coal;
      // margin-left: 10px;
    }

    &--dropdown {
      @include dropdown-arrow;

      &:after {
        display: inline-block;
        margin-left: 8px;
        vertical-align: middle;
      }

      #{$root}__item.is-open > & {
        &:after {
          transform: rotateZ(90deg);
        }
      }
    }
  }

  &__dropdown {
    display: none;

    #{$root}__item.is-open > & {
      display: block;
    }
  }
}
