.switch-accounts {
    $root: &;
    max-width: 760px;
    @include wrap;
    text-align: center;
    padding-top: 45px;
    padding-bottom: 85px;

    @media screen and (max-width: $br-mobile) {
        padding-top: 0;
        padding-bottom: 0;
        text-align: left;
    }

    &__intro {
        @include text-22;
        margin-bottom: 2em;
        
        @media screen and (max-width: $br-mobile) {
            margin-bottom: 1em;
        }
    }

    &__content {
        white-space: nowrap;
        $map: (
            $br-1440: 40px,
            $br-600: 20px,
        );
        @include fluid('margin-bottom', $map);
    }


    &__selected {
        @include text-22;
        font-weight: 700;
        margin-bottom: 1em;
    }

    &__cta {
    }

    &__error {
        @include text-16;
        align-self: center;
        color: $c-red;

        $map: (
            $br-1024: 22px,
            $br-480: 12px,
        );
        @include fluid('margin-top', $map);
    }
}