.app {
  height: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  &__content {
    height: 100%;
  }
}
