.address {
  $root: &;
  flex: 1 1 auto;
  width: 100%;
  margin-right: 7px;
  @include text-input;

  &--small {
    @include text-14;
  }

  .react-select__indicators {
    display: none;
  }

  &--default {
    .react-select__control {
      // @include input-default;
      // @include text-18;
      // min-height: 30px;

      &--is-focused {
        border-color: transparent;
        box-shadow: 0px 1px 5px 0px $c-orange;
      }
    }

    .react-select__control--is-focused {
      border-color: transparent !important;
      box-shadow: 0px 1px 5px 0px $c-orange !important;
    }

    .react-select__control--is-disabled {
      background-color: transparent !important;
    }

    .react-select__value-container {
      padding: 0;

      & > * {
        margin: 0;
        padding: 0;
      }
    }
  }
}
