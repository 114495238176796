input {
  appearance: none;
  border: 0;
  outline: none;
  background-color: transparent;
  width: 100%;
  font-family: $f-copy;

  &::-ms-clear,
  &::-ms-reveal {
    // Fix IE11 eye icon
    display: none;
    width: 0;
    height: 0;
  }

  &:invalid {
    box-shadow: none;
  }

  &:disabled {
    opacity: 1; // For iOs
  }

  &[type="password"] {
    font-weight: 400; // Fix for iOs huge password dots
  }
}

@mixin input-default() {
  display: block;
  width: 100%;
  border-radius: 4px;
  padding: 0 0.6em;
  height: 38px;
  line-height: 38px;
  border: 1px solid $c-stone;

  &:focus {
    border-color: transparent;
    box-shadow: 0px 1px 5px 0px $c-orange;
  }

  &:disabled {
    // color: $c-earth;
    color: $c-coal;
    -webkit-text-fill-color: $c-coal;
  }

  @include autofill(inherit, lighten($c-pale-orange, 5%));
}

@mixin input-minimal() {
  display: block;
  width: 100%;
  border-radius: 4px;
  padding: 0 0.83em;
  height: 38px;
  line-height: 38px;
  border: 1px solid $c-stone;
  background-color: $c-white;
  @include autofill(inherit, lighten($c-pale-orange, 5%));
}

@mixin input-shadow() {
  display: block;
  width: 100%;
  border-radius: 4px;
  padding: 0 0.77em;
  padding: 0 0.83em;
  height: 38px;
  line-height: 38px;
  border: 1px solid $c-stone;
  background-color: $c-white;
  box-shadow: 0px 3px 6px rgba($c-black, 0.16);
  @include autofill(inherit, lighten($c-pale-orange, 5%));
}

@mixin input-checkbox() {
  display: none;

  & + label {
    cursor: pointer;
    display: block;
    padding-left: 26px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      display: inline-block;
      @include size(18px);
      border: 2px solid $c-dirt;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 12px 9px;
      border-radius: 3px;
    }
  }

  // Box checked
  &:checked + label:before {
    background-image: url("../../images/icons/icon-checked.svg");
  }
}

@mixin input-radio() {
  display: none;

  & + label {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &:before,
    &:after {
      content: "";
      border-radius: 100%;
    }

    &:before {
      display: inline-block;
      @include size(20px);
      border: 2px solid $c-dirt;
    }

    &:after {
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-5px, -5px);
      @include size(10px);
      background-color: $c-dirt;
    }
  }

  &:checked + label:after {
    display: block;
  }
}

.input {
  position: relative;

  &__input {
    @include text-input;
    @include input-default;

    &--hidden {
      visibility: hidden;
    }
  }

  &__disabledLink {
    z-index: 3;
    display: block;
    position: absolute;
    @include pos(0);
    @include size(100%);
    @include text-18;
    padding: 0.46em 0.83em;
    border: 1px solid $c-stone;
    border-radius: 4px;
    line-height: inherit;
  }

  &__tooltip {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 40px;
  }

  &__suggestions {
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    z-index: 10;

    background-color: $c-white;
    border: 1px solid $c-stone;
    border-radius: 4px;
    // height: 0;
    max-height: 200px;
    overflow-y: auto;

    .is-open & {
      height: auto;
      background-color: $c-white;
    }
  }

  &__suggestion {
    display: block;
    padding: 0.46em 0.83em;
    @include text-18;

    &:hover {
      background-color: $c-pale-orange;
    }
  }
}
