.breadcrumbs {
  $root: &;
  display: flex;
  flex-wrap: wrap;
  @include text-12;
  font-family: $f-clarimo;
  font-weight: 400;
  margin: -3px 0;

  // &__item {
  & > * {
    white-space: nowrap;
    padding: 8px 3px;

    &:not(:last-child) {
      &:after {
        content: ">";
        display: inline-block;
        margin-left: 7px;
        // padding: 5px;

        @media screen and (max-width: $br-mobile) {
          // margin-left: 5px;
          // margin-right: 5px;
        }
      }
    }

    &:last-child {
      color: $c-earth;
    }
  }
}
