.link-group {
  $root: &;
  display: flex;
  align-items: center;

  & > * {
    flex: 0 1 auto;
    @include text-link-main;

    $map: (
      $br-1440: 30px,
      $br-1024: 10px,
    );
    @include fluid("margin-right", $map);

    &:last-child {
      margin-right: 0;
    }
  }
}
