//--- Palette
$c-white: #fff;
$c-black: #000;
$c-coal: #333333;
$c-dirt: #4d4d4d;
$c-earth: #7f7f7f;
$c-grey: #f4f4f4;
$c-shade: #dedede;
$c-smoke: #a9a9a9;
$c-stone: #dededf;
$c-orange: #ed9e4c;
$c-pale-orange: #f1e0cd;
$c-purple: #8f2b9d;
$c-dark-purple: #521345;
$c-banner-purple: #018d73;

$c-gallery: #f0f0f0;

$c-pale-purple: #d788e3;
$c-violet: #9b2583;
$c-red: #d92231;
$c-blue: #1a6fc5;
$c-water: #2895d5;
$c-light-blue: #2895d5;
$c-green: #6daf3d;
$c-emerald: #01916d;

//--- Key elements
$c-bg: $c-grey;
