.steps {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &__item {
    flex: 0 1 auto;
    margin: 0.5em 1em;
    font-weight: 400;
    color: rgba($c-coal, 0.6);

    &.is-current-step {
      color: $c-emerald;
      font-weight: 500;
    }

    &.is-clickable {
      cursor: pointer;
    }

    span:nth-child(1) {
      &:after {
        content: ".";
      }
    }

    @media screen and (max-width: $br-mobile) {
      margin: 0;
      padding-left: 13px;

      &:first-child {
        padding-left: 0;
      }

      span:nth-child(2) {
        display: none;
      }

      span:nth-child(1) {
        display: flex;
        align-items: center;

        &:after {
          content: "";
          @include size(6px);
          display: inline-block;
          border-radius: 6px;
          background-color: rgba($c-coal, 0.6);
          margin: 0 auto;
          margin-left: 13px;
        }
      }

      &:last-child {
        span:nth-child(1):after {
          content: none;
        }
      }
    }
  }
}
