.table-cell {
  $root: &;

  @include text-14;
  font-family: $f-clarimo;
  // border: 1px solid $c-earth;
  vertical-align: middle;
  text-align: left;
  position: relative;
  transition: color 1s ease;

  &--valign-top {
    vertical-align: top;
  }

  &.is-submitted {
    color: $c-green;
  }

  &__base {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    #{$root}--align-center & {
      justify-content: center;
    }

    #{$root}--align-left & {
      justify-content: center;
    }

    #{$root}--checkbox & {
      flex-direction: column;
      justify-content: center;
    }
  }

  &__sort-icon {
    flex: 0 0 auto;
    display: block;
    height: 22px;
    width: 22px;
    margin-left: 12px;
    cursor: pointer;

    background-image: url("../../images/icons/icon-sort-alpha.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .table-head--dark & {
    background-color: $c-black;
    color: $c-white;
  }

  @at-root th#{&} {
    padding: 14px 13px 10px;
    @include text-table-th;
    background-color: $c-gallery;

    @media screen and (max-width: $br-mobile) {
      padding: 0.72em 10px;
    }

    &--reading {
      width: 180px;

      @media screen and (max-width: $br-mobile) {
        width: 130px;
      }
    }

    &--invoice {
      @media screen and (min-width: $br-1280) {
        width: 240px;
      }
    }

    &--invoice-num {
      @media screen and (min-width: $br-lg) {
        width: 140px;
      }
    }

    &--contact-name {
      @media screen and (min-width: $br-md) {
        // width: 240px;
      }
    }

    &--member-of {
      width: 7em;
    }

    &--pending-serial {
      width: 16em;

      @media screen and (max-width: $br-mobile) {
        width: 100px;
      }
    }

    &--pending-select {
      width: 5em;

      @media screen and (max-width: $br-mobile) {
        width: 50px;
      }
    }

    &--equip-serial {
      @media screen and (min-width: $br-md) {
        width: 11em;
      }
    }

    &--checkbox {
      @include text-table-th;
      // display: flex;
      // align-items: center;
      // flex-direction: column;
      width: 74px;
      margin: 0 auto;

      @media screen and (max-width: $br-lg) {
        font-size: 11px;
        width: 54px;
      }
      @media screen and (max-width: $br-375) {
        font-size: 11px;
        width: 44px;
      }

      input[type="checkbox"] {
        @include input-checkbox;

        & + label {
          display: block;
          margin: 20px auto 0;
          padding-left: 0;
          @include size(18px);

          &:before {
            border-color: $c-white;
          }

          @media screen and (max-width: $br-mobile) {
            margin-top: 10px;
          }
        }

        &:checked + label:before {
          background-image: url("../../images/icons/icon-checked-white.svg");
        }
      }
    }
  }

  @at-root td#{&} {
    padding: 1em 13px;
    height: 50px;

    border-bottom: 1px solid $c-shade;

    @media screen and (max-width: $br-mobile) {
      padding: 1em 10px;
      height: 40px;
    }

    &--valign-top {
      height: auto;
    }

    &--type-narrow {
      padding: 0.6em 13px;

      @media screen and (max-width: $br-mobile) {
        padding: 0.6em 10px;
      }
    }

    &--radio {
      width: 72px;
      text-align: center;

      @media screen and (max-width: $br-mobile) {
        width: 56px;
      }
    }

    a {
      @include text-link;
    }
  }

  input[type="text"] {
    @include input-minimal;

    &:disabled {
      background-color: $c-stone;
    }
  }

  input[type="radio"] {
    @include input-radio;
  }
}
