.reveal {
  $root: &;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  padding-right: 40px;
  cursor: pointer;

  @media screen and (max-width: $br-600) {
    padding-right: 20px;
  }

  &.is-open {
    white-space: normal;
    cursor: auto;
  }

  &__icon {
    position: absolute;
    cursor: pointer;
    width: 40px;
    top: 0;
    right: -10px;
    height: 1.45455em;
    @include dropdown-arrow;
    display: flex;
    align-items: center;
    justify-content: center;

    .is-open & {
      @include dropdown-arrow--open;
    }

    @media screen and (max-width: $br-600) {
      width: 30px;
      right: -5px;
    }
  }
}
