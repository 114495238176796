.layout-page {
  $root: &;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  flex: 1 1 auto;
  position: relative;
  background-color: $c-white;

  &--grey {
    background-color: $c-grey;
  }

  &__wrap {
    width: 100%;
    flex: 1 1 auto;
    @include sides;
    @include wrap-1200;
    background-color: $c-white;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 5;

    #{$root}--grey & {
      background-color: $c-grey;
    }
  }

  &__hero {
    flex: 0 0 auto;

    @media screen and (max-width: $br-1024) {
      display: none;
    }
  }

  &__body {
    flex: 1 1 auto;
    display: flex;
    align-items: stretch;
    // $map: (
    //   $br-1920: 30px,
    //   $br-1280: 15px,
    // );
    // @include fluid("margin-top", $map);
    width: 100%;

    // @media screen and (max-width: $br-1024) {
    //   margin-top: 0;
    // }
  }

  &__sidebar {
    flex: 0 0 auto;
    align-self: stretch;
    // position: sticky;
    // @include header-height('top', 30px);
    // align-self: flex-start;
    background-color: $c-banner-purple;

    $map: (
      $br-1440: 258px,
      $br-1280: 180px,
      $br-1024: 158px,
    );
    @include fluid("width", $map);

    @media screen and (max-width: $br-1024) {
      display: none;
    }
  }

  &__content {
    flex: 1 1 auto;
    width: 100%;
    // $map: (
    //   $br-1920: 30px,
    //   $br-1280: 15px,
    // );
    // @include fluid("margin-left", $map);
    padding: 24px 0 50px 0;

    // $map: (
    //   $br-1920: 22px,
    //   $br-1280: 15px,
    // );
    // @include fluid("padding-top", $map);

    // $map: (
    //   $br-1920: 85px,
    //   $br-1280: 30px,
    // );
    // @include fluid("padding-right", $map);

    @media screen and (max-width: $br-1024) {
      // margin-left: 0;
      // @include sides;
    }

    @media screen and (max-width: $br-mobile) {
      padding-top: 12px;
    }
  }

  &__breadcrumbs {
    margin-bottom: 10px;
  }

  &__content-top {
    display: flex;
    justify-content: space-between;
    // align-items: flex-end;

    @media screen and (max-width: $br-1024) {
      display: block;
    }
  }

  &__title {
    flex: 0 1 auto;
    margin-right: 50px;
    @include text-24;
    color: $c-emerald;

    @media screen and (max-width: $br-1024) {
      margin-right: 0;
    }
  }

  &__control {
    flex: 0 1 auto;
    text-align: right;

    $map: (
      $br-1600: 7px,
      $br-1024 + 1: 3px,
    );
    @include fluid("margin-top", $map);

    & > * {
      margin-top: 20px;

      &:first-child {
        margin-top: 0;
      }
    }

    @media screen and (max-width: $br-1024) {
      margin-top: 1.5em;
    }
  }

  &__control-select {
    // display: inline-block;
    // width: 360px;
    margin-left: auto;

    @media screen and (max-width: $br-mobile) {
      margin-left: 0;
      width: 100%;
    }
  }

  &__content-bottom {
    // padding-left: 40px;
    margin-top: 30px;

    // @media screen and (max-width: $br-1440) {
    //   padding-left: 0;
    // }

    @media screen and (max-width: $br-mobile) {
      /*
      Fix:
      Searching for equipment and producing no results condenses the white screen 
      so much that the pink is displayed above the open keyboard (Android only, image 6)
      */
      min-height: 210px;

      margin-top: 20px;

      .service-call & {
        display: flex;
        flex-direction: column;

        & > * {
          flex: 0 1 auto;
        }
      }
    }
  }

  &__action {
    display: flex;
    justify-content: flex-end;
    $map: (
      $br-1024: 40px,
      $br-480: 20px,
    );
    @include fluid("margin-top", $map);

    @media screen and (max-width: $br-mobile) {
      .service-call & {
        display: block;
        order: -1;
        margin-top: 0;
        @include fluid("margin-bottom", $map);
      }
    }
  }

  &__message {
    margin: 1.5em auto;
    width: 100%;
    max-width: 28em;
    text-align: center;
  }

  &__footer {
    flex: 0 1 auto;
    width: 100%;
    margin-top: auto;
    padding-top: 0;
    position: relative;
    z-index: 5;

    @media screen and (max-width: $br-1024) {
      padding-top: 0;
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    max-height: 1250px;
    width: 50vw;
    background-image: url("../../images/bg.jpg");
    //background-image: linear-gradient(to bottom, transparent, $c-white),
    //  url("../../images/bg.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 1;

    #{$root}--grey & {
      background-image: url("../../images/bg.jpg");
      max-height: 100%;
    }
  }
}
