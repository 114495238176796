.notice {
    $root: &;
    max-width: 760px;
    @include wrap;
    text-align: center;
    @include vertical-space('margin-top', 8);
    @include vertical-space('margin-bottom', 8);

    &__h {
        @include text-50;
        margin-bottom: 0.6em;
    }

    &__text {

    }
}