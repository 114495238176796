.loading {
    position: fixed;
    z-index: $z-loading;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba($c-black, 0.5);

    display: flex;
    align-items: center;
    justify-content: center;

    &__icon {
        flex: 0 1 auto;
        @include size(100px);

        @media screen and (max-width: $br-600) {
            @include size(50px);            
        }

        @keyframes spin {
            from { transform: rotate(0deg); }
            to { transform: rotate(360deg); }
        }

        animation: spin 1s steps(12, end) infinite;

        i {
            margin-left: -3px;
            display: block;
            position: absolute;
            left: 50%;
            background: #fff;
            border-radius: 3px;

            height: 24px;
            width: 6px;
            transform-origin: center 50px;

            @media screen and (max-width: $br-600) {
                height: 12px;
                width: 4px;
                margin-left: -2px;
                transform-origin: center 25px;           
            }

            &:nth-child(1) { opacity:.08 }
            &:nth-child(2) { transform:rotate(30deg);opacity:.167 }
            &:nth-child(3) { transform:rotate(60deg);opacity:.25 }
            &:nth-child(4) { transform:rotate(90deg);opacity:.33 }
            &:nth-child(5) { transform:rotate(120deg);opacity:.4167 }
            &:nth-child(6) { transform:rotate(150deg);opacity:.5 }
            &:nth-child(7) { transform:rotate(180deg);opacity:.583 }
            &:nth-child(8) { transform:rotate(210deg);opacity:.67 }
            &:nth-child(9) { transform:rotate(240deg);opacity:.75 }
            &:nth-child(10) { transform:rotate(270deg);opacity:.833 }
            &:nth-child(11) { transform:rotate(300deg);opacity:.9167 }
            &:nth-child(12) { transform:rotate(330deg);opacity:1 }
        }
    }
}