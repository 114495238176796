.location-field {
  $root: &;
  display: flex;
  align-items: center;
  // border: 1px solid $c-earth;
  // background-color: $c-white;
  // border-radius: 2px;
  //   height: 60px;
  flex: 1 1 auto;
  @include text-14;
  font-family: $f-clarimo;
  margin: 0 18px;
  position: relative;

  @media screen and (max-width: $br-mobile) {
    margin: 0;
  }

  &__value {
    flex: 0 1 auto;
    font-weight: 400;
    padding: 0.46em 0.83em;

    @media screen and (max-width: $br-mobile) {
      padding-left: 0;
      flex: 1 1 auto;
      width: 100%;
    }

    .is-editing & {
      // display: none;
      opacity: 0;
      pointer-events: none;
    }
  }

  &__change {
    flex: 0 0 auto;
    // margin-left: 20px;
    @include text-link-main;
    font-weight: 300;

    @media screen and (max-width: $br-mobile) {
      flex: 0 1 auto;
    }

    .is-editing & {
      // display: none;
      pointer-events: none;
      opacity: 0;
    }
  }

  &__field {
    display: none;
    position: absolute;
    left: 0;

    .is-editing & {
      flex: 1 1 auto;
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
}
