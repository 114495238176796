@mixin dropdown-arrow() {
  &:after {
    content: "";
    flex: 0 0 auto;
    display: block;
    @include size(7px, 4px);
    background-image: url("../../images/icons/icon-arrow-down.svg");
    background-position: center;
    background-repeat: no-repeat;
  }
}

@mixin dropdown-arrow--open() {
  &:after {
    transform: rotateZ(180deg);
  }
}
