.panel-notification {
    $root: &;
    flex: 0 1 auto;
    display: block;
    max-width: 721px;
    width: 100%;
    background-color: $c-white;
    border-radius: 4px;
    position: relative;

    @media screen and (max-width: $br-mobile) {
        max-width: 400px;
    }

    &__wrap {
        padding: 40px 23px 23px;
        
        @media screen and (max-width: $br-mobile) {
            padding: 25px 15px 15px;
        }
    }

    &__text {
        text-align: center;
        padding-left: 60px;
        padding-right: 60px;

        @media screen and (max-width: $br-mobile) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__control {
        margin-top: 1.36em;
        text-align: right;
    }
}