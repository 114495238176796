﻿.consumable-return-policy-panel {
    $root: &;
    background-color: $c-emerald;
    padding: 40px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $c-white;
    width: 100%;
    height: 100%;

    @media screen and (max-width: $br-1024) {
        @include side("padding-left");
        @include side("padding-right");
        padding-top: 40px;
        padding-bottom: 30px;
    }

    &__content {
        max-width: 900px;
        width: 100%;
    }

    &__head {
        text-align: center;

        h2 {
            margin-bottom: 10px;
        }

        p {
            @include text-14;
            font-family: $f-clarimo;
        }
    }

    &__item {
        margin-top: 54px;
        text-align: center;

        @media screen and (max-width: $br-1024) {
            display: none;
        }
    }

    &__item-h {
        @include text-18;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    &__item-text {
        @include text-14;
        font-family: $f-clarimo;
    }
}
