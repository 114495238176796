.strength-meter {
  height: 4px;
  width: 100%;
  background-color: $c-stone;
  position: relative;

  &__bar {
    @include size(100%);

    &--0 {
      background-color: $c-red;
      width: 25%;
    }

    &--1 {
      background-color: $c-red;
      width: 25%;
    }

    &--2 {
      background-color: $c-orange;
      width: 50%;
    }

    &--3 {
      background-color: $c-water;
      width: 75%;
    }

    &--4 {
      background-color: $c-green;
      width: 100%;
    }
  }

  &__message {
    position: absolute;
    right: 0;
    top: 100%;
    @include text-12;
    padding-top: 6px;

    @media screen and (max-width: $br-600) {
      padding-top: 3px;
    }
  }
}
