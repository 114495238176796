.search {
  display: inline-flex;
  align-items: center;
  max-width: 100%;

  @media screen and (max-width: $br-1024) {
    display: flex;
    width: 100%;
  }

  @media screen and (max-width: $br-mobile) {
    display: block;
  }

  &__pre {
    flex: 0 1 auto;
    @include text-16;
    margin-right: 10px;

    span {
      &:nth-child(1) {
        @media screen and (max-width: $br-mobile) {
          display: none;
        }
      }

      &:nth-child(2) {
        display: none;
        @media screen and (max-width: $br-mobile) {
          display: block;
        }
      }
    }

    @media screen and (max-width: $br-mobile) {
      margin-bottom: 0.15em;
      text-align: left;
    }
  }

  &__field {
    flex: 0 1 auto;
    width: 420px;
    position: relative;

    input {
      @include input-minimal;
      @include text-input;
      padding-right: 50px;

      @media screen and (max-width: $br-mobile) {
        height: 38px;
      }
    }

    @media screen and (max-width: $br-1024) {
      width: 100% !important;
    }

    @media screen and (max-width: $br-mobile) {
      // width: ;
    }
  }

  &__input {
    position: relative;
  }

  &__error {
    position: absolute;
    @include text-input-error;
    bottom: 100%;
    right: 0;
    padding: 2px 0;

    &--top {
    }

    &--bottom {
      top: 100%;
    }

    @media screen and (max-width: $br-mobile) {
      position: static;
      text-align: left;
    }
  }

  &__button {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 60px;
    background-image: url("../../images/icons/icon-search.svg");
    background-position: center;
    background-repeat: no-repeat;

    .is-search-active & {
      background-image: url("../../images/icons/icon-search-green.svg");
    }

    @media screen and (max-width: $br-mobile) {
      width: 40px;
    }
  }
}
