.panel {
  $root: &;
  flex: 0 1 auto;
  display: block;
  margin: 0 auto;
  background-color: $c-white;
  border-radius: 10px;
  position: relative;

  display: flex;
  flex-direction: column;

  max-width: 400px;
  width: 100%;

  $map: (
    $br-1920: 47px,
    $br-480: 36px,
  );
  @include fluid("padding-top", $map);
  @include fluid("padding-bottom", $map);

  padding-left: 52px;
  padding-right: 52px;

  @media screen and (max-width: $br-420) {
    padding-left: 30px;
    padding-right: 30px;
  }

  &__wrap {
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    $map: (
      $br-1920: 426px,
      $br-1280: 380px,
    );
    @include fluid("min-height", $map);

    #{$root}--action & {
      min-height: 0;
    }

    #{$root}--close & {
      // padding-top: 50px;

      // $map: (
      //   $br-1024: 50px,
      //   $br-600: 20px,
      // );
      // @include fluid("padding-top", $map);
    }
  }

  &__content {
    flex: 0 1 auto;
  }

  &__title {
    flex: 0 1 auto;
    text-align: center;
    margin-bottom: 0.22em;
    margin-left: -20px;
    margin-right: -20px;

    .modal & {
      margin-bottom: 0.67em;
    }
  }

  &__text {
    flex: 0 1 auto;
    text-align: center;
    margin-bottom: 20px;
    // margin-left: -1em;
    // margin-right: -1em;
    // @include text-22;

    .button {
      margin-top: 2.5em;
    }

    #{$root}--action & {
      margin-left: 0;
      margin-right: 0;
      padding-left: 1em;
      padding-right: 1em;
      margin-bottom: 25px;

      @media screen and (max-width: $br-375) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &__bottom {
    flex: 0 1 auto;
    margin-top: 30px;
    text-align: center;
    @include text-14;
    font-family: $f-clarimo;
  }

  &__very-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    margin-top: 1.36em;
    text-align: center;
  }

  &__person {
    display: block;
    font-weight: 500;
  }

  &__action {
    display: flex;

    @media screen and (max-width: $br-mobile) {
      display: block;
    }

    & > * {
      flex: 0 1 auto;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }

      @media screen and (max-width: $br-mobile) {
        margin-right: 0;
        margin-top: 10px;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  &__close {
    @include size(24px);
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;

    &:after,
    &:before {
      content: "";
      position: absolute;
      top: 10px;
      left: 3px;
      @include size(18px, 2px);
      background-color: $c-dirt;
    }

    &:after {
      transform: rotateZ(45deg);
    }

    &:before {
      transform: rotateZ(-45deg);
    }
  }
}
