.notification-bar {
    position: fixed;
    z-index: $z-bar;
    @include header-height('top');
    left: 0;
    width: 100%;
    background-color: $c-green;
    @include sides;
    padding-top: 10px;
    padding-bottom: 10px;
    
    &--blue {
        background-color: $c-light-blue;
    }
    
    &--red {
        background-color: $c-red;
    }


    &__content {
        margin-top: auto;
        margin-bottom: auto;
        outline: none;
        color: $c-white;
        text-align: center;
        font-weight: 700;
    }
}