html {
  height: 100%;
}

#root {
  height: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}
