.form-field {
  $root: &;
  position: relative;
  flex: 0 1 auto;
  width: 100%;

  &--label-above {
  }

  &--label-inline {
    display: flex;
    align-items: center;

    @media screen and (max-width: $br-mobile) {
      display: block;
    }
  }

  &__label {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0.15em;

    @media screen and (max-width: $br-mobile) {
      display: block;
      margin-bottom: 0.3em;
    }

    label {
      white-space: nowrap;
      display: block;
      flex: 0 1 auto;
      @include text-input-label;
      text-align: left;

      @media screen and (max-width: $br-mobile) {
        white-space: initial;
      }
    }

    #{$root}--label-inline & {
      margin-right: 10px;
      margin-bottom: 0;

      @media screen and (max-width: $br-mobile) {
        margin-right: 0;
        margin-bottom: 0.3em;
      }
    }

    .form__details & {
      width: 7.2em;
    }
  }

  &__field {
    flex: 1 1 auto;
  }

  &__asterisk {
    display: inline-block;
    color: $c-red;
    margin-left: 0.2em;

    &:after {
      content: "*";
    }
  }

  &__error {
    flex: 1 1 auto;
    @include text-input-error;
    align-self: center;
    margin-left: 6px;

    #{$root}--label-above & {
      @media screen and (min-width: $br-mobile + 1) {
        // margin-bottom: -2px;
      }
    }

    #{$root}--label-inline & {
      position: absolute;
      @include text-12;
      font-weight: 500;
      padding-bottom: 1px;
      bottom: 100%;
      right: 0;
    }

    @media screen and (max-width: $br-mobile) {
      margin-left: 0;
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }

  &__tooltip {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    @include size(19px);
    margin-left: 5px;
    margin-top: -2px;
  }
}
