button {
  border: 0;
  outline: 0;
}

@mixin button-solid($bg-color: $c-emerald) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  background-color: $bg-color;
  padding: 0.75em 1.35em;
  border-radius: 4px;
  transition: background-color 0.15s ease;

  &:hover {
    background-color: darken($bg-color, 6%);
  }

  &:active {
    background-color: darken($bg-color, 16%);
  }

  .button__content {
    transform: translateY(2px);
    flex: 0 1 auto;
    color: $c-white;

    @include font(18px, 18px, 0, 700, uppercase);
    font-family: $f-clarimo;
    white-space: nowrap;
  }

  .button__tooltip {
    flex: 0 0 auto;
    position: relative;
    @include size(19px);
    margin-left: 10px;
  }
  &.is-button-disabled {
    background-color: $c-smoke !important;
    cursor: not-allowed;
    pointer-events: none;

    .button__tooltip {
      pointer-events: auto;
    }
  }
}

.button {
  cursor: pointer;
  @include text-18;
  line-height: 18px;

  &.is-no-action {
    cursor: default;
  }

  &--outline {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid $c-stone;
    padding: 0.77em 1.94em;
    color: $c-blue;
    text-decoration: underline;
  }

  &--fullWidth {
    width: 100%;
  }

  &--solid {
    @include button-solid;
  }

  &--cancel {
    @include button-solid($c-smoke);
  }

  &--action {
    @include button-solid($c-red);
  }

  &--solid-small {
    @include button-solid;
    padding: 0.35em 0.8em;
    width: auto;
  }

  &--solid-medium {
    flex: 0 1 auto;
    @include button-solid;
    display: inline-flex;
    width: auto;
    padding-left: 3em;
    padding-right: 3em;

    @media screen and (max-width: $br-mobile) {
      display: flex;
      width: 100%;
    }
  }

  &--solid-inline {
    flex: 0 1 auto;
    @include button-solid;
    display: inline-flex;
    width: auto;
    padding-left: 7em;
    padding-right: 7em;

    @media screen and (max-width: $br-mobile) {
      display: flex;
      width: 100%;
    }
  }

  &--text {
    // text-transform: uppercase;
    color: $c-blue;
    cursor: pointer;
    text-decoration: underline;
  }

  &__company {
    @include text-12;
    font-weight: 700;
  }
}
