.welcome-panel {
  $root: &;
  background-color: $c-emerald;
  padding: 40px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $c-white;
  width: 100%;
  height: 100%;

  @media screen and (max-width: $br-1024) {
    @include side("padding-left");
    @include side("padding-right");
    padding-top: 40px;
    padding-bottom: 30px;
  }

  &__content {
    max-width: 400px;
    width: 100%;
  }

  &__head {
    text-align: center;

    h2 {
      margin-bottom: 10px;
    }

    p {
      @include text-14;
      font-family: $f-clarimo;
    }
  }

  &__items {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: $br-1024) {
      display: none;
    }
  }

  &__item {
    flex: 1 1 auto;
    width: 50%;
    text-align: center;

    &:nth-child(1) {
      border-right: 1px solid $c-white;
      border-bottom: 1px solid $c-white;
      padding-bottom: 40px;
      padding-right: 20px;
    }

    &:nth-child(2) {
      border-bottom: 1px solid $c-white;
      padding-bottom: 40px;
      padding-left: 20px;
    }

    &:nth-child(3) {
      border-right: 1px solid $c-white;
      padding-top: 40px;
      padding-right: 20px;
    }

    &:nth-child(4) {
      padding-top: 40px;
      padding-left: 20px;
    }
  }

  &__item-icon {
    margin-bottom: 20px;
    height: 94px;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    img {
      flex: 0 1 auto;
      width: auto;
    }
  }

  &__item-title {
    @include text-14;
    font-family: $f-clarimo;
    text-transform: uppercase;
    font-weight: 500;
    max-width: 140px;
    margin: 0 auto;
  }

  &__footer {
    margin-top: 54px;
    text-align: center;

    @media screen and (max-width: $br-1024) {
      display: none;
    }
  }

  &__footer-h {
    @include text-18;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  &__footer-text {
    @include text-14;
    font-family: $f-clarimo;
  }
}
