.modal {
  &__overlay {
    position: fixed;
    z-index: $z-modal;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba($c-black, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    @include sides;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  &__content {
    margin-top: auto;
    margin-bottom: auto;
    outline: none;
    flex: 0 1 auto;
    // width: 100%;
  }
}
