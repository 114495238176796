.dropdown {
  $root: &;
  position: absolute;
  z-index: $z-dropdown;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 3px 16px 24px rgba($c-black, 0.16);
  background-color: $c-white;

  &__item {
    white-space: nowrap;
  }

  &__title {
    padding: 1.13em 1.8em;
    display: block;
    position: relative;
    cursor: pointer;

    &:hover {
      background-color: rgba($c-emerald, 0.2);
    }

    #{$root}__dropdown & {
      padding-left: 4.7em;
    }

    &--dropdown {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: default;

      @include dropdown-arrow;

      &:after {
        margin-left: 8px;
      }

      #{$root}__item:hover > & {
        @include dropdown-arrow--open;
      }
    }

    &.is-current {
      background-color: rgba($c-emerald, 0.2);
    }
  }

  &__dropdown {
    display: none;

    #{$root}__item:hover > & {
      display: block;
    }
  }
}
