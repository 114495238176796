.home {
  $root: &;
  max-width: 850px;
  @include wrap;
  text-align: center;

  &__h {
    // @include text-50;
    margin-bottom: 10px;
    color: $c-emerald;
  }

  &__sub {
    @include text-16;
    font-family: $f-clarimo;
  }

  //   &__text {
  //     @include text-22;

  //     ul {
  //       margin-top: 1.5em;
  //       text-align: left;
  //       padding-left: 2em;

  //       li {
  //         list-style: disc;
  //       }
  //     }
  //   }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    margin-right: -18px;
    margin-top: 30px;

    & > * {
      width: calc(20% - 18px);
      flex: 0 1 auto;
      margin-right: 18px;
      margin-bottom: 18px;

      @media screen and (max-width: $br-680) {
        width: calc(50% - 18px);
      }
    }
  }

  &__banner {
    border-radius: 8px;
    overflow: hidden;
    margin-top: 22px;
  }

  &__headlines {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media screen and (max-width: $br-680) {
      display: block;
    }
  }

  &__headline {
    text-align: left;
    flex: 1 1 auto;
    width: 50%;
    max-width: 300px;

    @media screen and (max-width: $br-680) {
      width: auto;
      margin-top: 40px;
    }

    h4 {
      margin-bottom: 10px;
    }

    p {
      @include text-14;
      font-family: $f-clarimo;
    }
  }
}
